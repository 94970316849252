































import { Component, Vue } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';

@Component
export default class ValidatedAppointment extends Vue {
    step = 1;

    get tradename() {
        return this.$route.query.tradename;
    }

    async store() {
        try {
            await appointmentModule.validatedAppointment({
                idCita: this.$route.params.id
            });
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
